.hotel-contact {
    padding:40px 0 100px 0;
    font-family: var(--font-default-medium);
    @media screen and (max-width: 767px) {
        padding:40px 0;
        text-align:center;
    }
}

.hotel-contact__phone {
    font-size:1.45rem;
}