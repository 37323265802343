.pagination {
    align-content: center;
    margin:3rem auto;
    justify-content: center;
}
.page-link.page-link {
    background: transparent;
    border: 0;
    border-radius: 0 !important;
}
.active .page-link {
    background: var(--color-primary) !important;
}