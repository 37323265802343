body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-mobile-default);
    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
    }
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

.font-medium {
    font-family: var(--font-default-medium);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    text-align: center;
    position: relative;
    font-size: calc(24rem/16);
    padding-bottom:40px;
    text-transform: none !important;
    @media screen and (min-width: 768px) {
        font-size: calc(36rem/16);
        padding-bottom:70px;
    }
}
h1:after, .h1:after {
    content:'';
    position: absolute;
    left:50%;
    bottom:18px;
    transform: translateX(-50%);
    width:90px;
    height:2px;
    background:var(--color-primary);
    @media screen and (min-width: 768px) {
        bottom:35px;
        width:140px;
    }
}
h1.text-left:after,
.h1.text-left:after {
    left:0;
    transform: translateX(0);
}
h1 b, .h1 b,
h1 strong, .h1 strong,
h1.strong, .h1.strong {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
h2, .h2 {
    font-size: calc(20rem/16);
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        font-size: calc(26rem/16);
    }
}
h3, .h3 {
    font-size: calc(18rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(22rem/16);
    }
}
h4, .h4 {
    font-size: 15px;
    font-family: var(--font-default-medium);
    color:var(--color-primary);
    text-transform: uppercase;
}
h5, .h5 {
    font-size: 13px;
}
h6, .h6 {
    font-size: 10px;
}


/* bg-primary adaptions */
.bg-primary h4, .bg-primary .h4 {
    color:#fff;
}
.bg-primary h1:after, .bg-primary .h1:after {
    background:#fff;
}