.slider:not(.slick-slider)>div+div{
    display: none;
}

.slider {
    position: relative;
}

/* bugfix https://github.com/kenwheeler/slick/issues/1961 */
.slick-slide { -webkit-backface-visibility: hidden; }

/* arrows */
.slider .slick-arrow {
    position: absolute;
    right:20px;
    top:50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #fff;
    width:64px;
    height:64px;
    background-color: rgba(0,0,0,0.2);
    border-radius: 50%;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    z-index: 10;
    @media screen and (max-width: 767px) {
        width:40px;
        height:40px;
        right:10px;
    }
}
.slider .slick-arrow:hover,
.slider .slick-arrow:focus {
    background-color: rgba(0,0,0,0.33);
}
.slider .slick-prev {
    right:auto;
    left:20px;
    @media screen and (max-width: 767px) {
        right:auto;
        left:10px;
    }
}
.slider .slick-arrow:before {
    content: var(--icon-arrow-next);
    font-family: "iconfont";
    display: block;
    speak: none;
    font-size: 1.5rem;
    line-height: 1;
    @media screen and (max-width: 767px) {
        font-size: 1rem;
    }
}
.slider .slick-prev:before {
    content: var(--icon-arrow-back);
}
.slider .slick-next:before {
    content: var(--icon-arrow-next);
}


/* dots */
.slider .slick-dots {
    position: absolute;
    left:-199999px;
}