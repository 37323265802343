.page-nav {
    position: relative;
    padding:0;
    text-align: center;
    margin-bottom:3rem;
    margin-top:-.5rem;
    @media screen and (min-width: 768px) {
        margin-bottom:5rem;
    }
}


.page-nav a {
    font-family: var(--font-default);
    padding:.5rem 2rem;
    display: inline-block;
}

@media screen and (max-width: 767px) {
    .page-nav{
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        display: block;
    }
    .page-nav a:last-of-type{
        padding-right:6rem;
    }
    .page-nav-wrapper.is-sticky{
        position: fixed;
        left:0;
        top:0;
        right:0;
        z-index:999;
        transform: translateY(50px);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    }
    .page-nav-wrapper.is-sticky .page-nav{
        margin:0;
    }
    .page-nav-wrapper {
        position: relative;
        transition: all 250ms ease;
    }
    .page-nav-wrapper:after{
        content:'';
        display: block;
        position:absolute;
        right:0;
        top:0;
        bottom:0;
        width:80px;
        height:auto;
        z-index:4;
        pointer-events: none;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3f3f3+0,f3f3f3+100&0+0,1+100 */
        background: -moz-linear-gradient(left, rgba(243,243,243,0) 0%, rgba(243,243,243,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(243,243,243,0) 0%,rgba(243,243,243,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(243,243,243,0) 0%,rgba(243,243,243,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f3f3f3', endColorstr='#f3f3f3',GradientType=1 ); /* IE6-9 */
    }
    .page-nav-wrapper:before{
        content:var(--icon-arrow-next);
        font-family: iconfont;
        display: block;
        position: absolute;
        right:5px;
        top:8px;
        z-index:5;
        pointer-events: none;
    }
}