.headerimg {
    position: relative;
    margin-bottom: 22px;
}
.headerslider .headerimg {
    margin-bottom: 0;
}

@media screen and (max-width: 767px) {
    .headerslider .slick-arrow {
        margin-top:-11px;
    }
}
.headerimg:after {
    content:'';
    width:auto;
    height:50%;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    z-index:1;
}
.headerimg__overlay {
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    text-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
    z-index:10;
}
.headerimg__overlay-hl {
    font-size: 22px;
    font-family: var(--font-default-bold);
    text-transform: uppercase !important;
    text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.25);
    margin:0;
    padding:0;

    @media screen and (min-width: 768px) {
        font-size: 54px;
    }
}
.headerimg__overlay-hl:after {
    display: none;
}
.headerimg__overlay-btn {
    position: relative;
    top:18px;
}