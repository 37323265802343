

.sharing-bar:before {
    font-family: iconfont;
    content: var(--icon-sharing);
    font-size: 12px;
    margin-right: 10px;
}

.shariff ul {
    list-style: none;
    padding-left: 0;
}

.shariff ul li {
    display: inline-block;
    padding: 5px;
}

.shariff ul li:not(:last-child) {
}

.shariff-button {
    font-family: iconfont;
}

.shariff-button a {
    display: inline-block;
    text-align: center;
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 50%;
}

.shariff-button .fab {
    position: relative;
    top: 4px;
}

.facebook .fab:before {
    content: '\EA09';
}

.twitter .fab:before {
    content: '\EA10';
}

.googleplus .fab:before {
    content: '\EA0A';
}