.row.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left: 0;
    padding-right: 0;
}

.row.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left: 5px;
    padding-right: 5px;
}
.row.row--gutter-width-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left: 10px;
    padding-right: 10px;
}
@media screen and (min-width: 768px) {
    .row.row--gutter-width-40 {
        margin-left: -20px;
        margin-right: -20px;
    }
    .row--gutter-width-40 > .col,
    .row--gutter-width-40 > [class^="col"],
    .row--gutter-width-40 > [class*=" col"] {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (min-width: 768px) {
    .row.row--gutter-width-60 {
        margin-left: -30px;
        margin-right: -30px;
    }
    .row--gutter-width-60 > .col,
    .row--gutter-width-60 > [class^="col"],
    .row--gutter-width-60 > [class*=" col"] {
        padding-left: 30px;
        padding-right: 30px;
    }
}
