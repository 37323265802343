/* desktop */
@media screen and (min-width: 768px) {
    .navbar {
        height:68px;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
        transition: all 350ms ease;
    }
    .navbar-nav {
        width: 800px;
        display: flex;
        justify-content: space-between;
    }
    body.has-headerimg .navbar {
        background-color: rgba(0,0,0,0.2);
        box-shadow: none;
    }
    body.has-headerimg .navbar.is-sticky,
    .navbar.is-sticky {
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    }
    .nav-item {
        display: flex;
        flex-basis: 20%;
        justify-content: center;
    }
    .nav-item-1 {
        flex-basis: 12%;
    }
    .nav-item-2 {
        flex-basis: 28%;
    }
    .nav-item-4 {
        flex-basis: 23%;
    }
    .nav-item-5 {
        flex-basis: 17%;
    }
    .navbar-nav .nav-link.nav-link {
        position: relative;
        display: inline-block;
        color:#000;
        height:68px;
        line-height:68px;
        padding:0 1.75rem;
        font-size:15px;
        text-transform: uppercase;
        white-space: nowrap;
        font-family: var(--font-default-medium);
    }
    body.has-headerimg .navbar:not(.is-sticky) .nav-link.nav-link {
        color:#fff;
    }
    .navbar-nav .nav-item.active .nav-link:before,
    .navbar-nav .nav-item:hover .nav-link:before,
    .navbar-nav .nav-link.nav-link:hover:before,
    .navbar-nav .nav-link.nav-link:focus:before {
        content:'';
        position: absolute;
        left:1.75rem;
        bottom:15px;
        right:1.75rem;
        width:auto;
        height:2px;
        background:var(--color-primary);
        transition: all 200ms ease;
    }
    .navbar-nav .nav-link.nav-link--brand {
        height:auto;
        padding:0;
        position: relative;
        flex-basis: 28%;
    }
    .navbar-nav .nav-link.nav-link--brand:before {
        display: none;
    }
    .navbar-nav .nav-link.nav-link--brand>img {
        display: block;
        position: absolute;
        top: -1px;
        left:50%;
        transform: translateX(-50%);
        box-shadow: 0 0 12px 0 rgba(0,0,0,0.1);
        width:150px;
        height:auto;
    }
    .navbar-nav .nav-link.nav-link--brand>img.brand-logo-sticky {
        display: none;
    }
    .navbar.is-sticky .nav-link.nav-link--brand>img.brand-logo-default{
        display: none;
    }
    .navbar.is-sticky .nav-link.nav-link--brand>img.brand-logo-sticky {
        display: block;
        width:auto;
        height:58px;
        box-shadow:none;
        margin-top:5px;
    }
    .lang-nav {
        position: absolute;
        right:15px;
        top:20px;
    }
    .lang-nav__item {
        display: inline-block;
        padding:0 .25rem;
        font-size: 13px;
        color: #a8a6a7;
        text-transform: uppercase;
        font-family: var(--font-default-medium);
    }
    .lang-nav__item a:hover,
    .lang-nav__item a:focus {
        text-decoration: underline;
    }
    body.has-headerimg .navbar:not(.is-sticky) .lang-nav__item {
        color:#fff;
    }

    /* dropdown */
    li.nav-item:focus .dropdown-menu,
    li.nav-item:hover .dropdown-menu {
        display: block;
    }
    .dropdown-menu {
        width:310px;
        margin:-.125rem 0 0 -155px;
        left: 50%;
        padding: 0;
        text-align: center;
        border: 0;
        border-radius: 0;
        box-shadow: 0 0 12px 0 rgba(0,0,0,0.1);
    }
    .dropdown-item {
        padding: .75rem;
        margin:0;
        font-weight: 300;
        font-size:14px;
        color: #000;
        text-transform: uppercase;
        font-family: var(--font-default-medium);
    }
    .dropdown-item span {
        display: block;
        font-style: italic;
        text-transform: none;
    }
    .dropdown-item.active, .dropdown-item:active {
        color: inherit;
        background-color: #f8f9fa;
    }
}

@media screen and (min-width: 1200px) {
    .navbar-nav {
        width: 950px;
    }
    .navbar-nav .nav-link.nav-link--brand>img {
        width:200px;
    }
}


.nav-item .toggle-subnav-icon {
    font-size:8px;
    padding:1rem .5rem;
    position: absolute;
    top: 0.8625rem;
    right:1rem;
}

body.has-headerimg .nav-item .toggle-subnav-icon {
    color:#fff;
}
li.is-open .dropdown-menu {
    display: block;
}


/* mobile */
@media screen and (max-width: 767px) {
    .navbar {
        height:50px;
        margin:0;
        padding:0;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    }
    .navbar-toggler {
        background:var(--color-primary);
        position: absolute;
        right: 16px;
        top: 6px;
        padding: .5rem .75rem;
        border-radius: 0;
    }
    .navbar-toggler>.icon {
        color:#fff;
        font-size: 28px;
        line-height: 1px;
        position: relative;
        top: 7px;
    }
    .navbar-toggler.collapsed>.icon:before {
        content:var(--icon-menu);
    }
    .navbar-brand {
        display: inline-block;
        margin: 0;
        padding: 0;
        width: 90px;
        position: absolute;
        left: 20px;
        top: 0;
        box-shadow: 0 0 12px 0 rgba(0,0,0,0.1);
        z-index:10;
        transition: all 200ms ease;
    }
    .navbar-brand>img.brand-logo-sticky {
        display: none;
    }
    .navbar.is-sticky .navbar-brand>img.brand-logo-sticky,
    body.nav-opened .navbar-brand>img.brand-logo-sticky {
        display: block;
    }
    body.nav-opened .navbar-brand>img.brand-logo-default,
    .navbar.is-sticky .navbar-brand>img.brand-logo-default{
        display: none;
    }
    body.nav-opened .navbar-brand,
    .navbar.is-sticky .navbar-brand{
        box-shadow: none;
        width: 50px;
        margin-top:4px;
    }
    .navbar-collapse {
        position: absolute;
        top:100%;
        left:0;
        right:0;
        padding-top:.5rem;
        background:#fff;
        text-align: center;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.25);
        max-height:calc(100vh - 50px);
        overflow-y:scroll;
    }
    body.has-headerimg .nav-item .toggle-subnav-icon,
    .nav-item .toggle-subnav-icon {
        top: 4px;
        right:auto;
        color:#000;
    }
    .nav-item.is-open .icon {
        transform: rotate(180deg);
    }
    .lang-nav {
        margin-bottom: 30px;
        border-top:1px solid var(--color-primary);
    }
    .lang-nav__item a,
    .navbar-nav .nav-link {
        display: inline-block;
        color:#000;
        padding:.75rem .5rem;
        font-size:16px;
        text-transform: uppercase;
        font-family: var(--font-default-medium);
    }
    .dropdown-menu {
        border-radius: 0;
        padding:0;
        margin:.5rem 0;
        box-shadow:none;
        text-align: center;
        border:1px solid var(--color-primary);
        border-width:1px 0;
    }
    .dropdown-item {
        display: inline-block;
        color: #000;
        padding:.5rem 1rem;
        font-size:15px;
        text-transform: uppercase;
        font-family: var(--font-default-medium);
    }
    .dropdown-item.active, .dropdown-item:active {
        color: inherit;
        background-color: #f8f9fa;
    }
    .dropdown-item span {
        display: block;
        font-style: italic;
        text-transform: none;
        font-size:14px;
    }
}