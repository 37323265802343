html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background: url(/static/img/pattern.png);
}
body.has-headerimg {
    background: #fff;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
        overflow-x: visible;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

.page-wrapper {
    position: relative;
    width:100%;
    margin:0 auto;
    padding-top:240px;
    max-width:1920px;
    @media screen and (max-width: 767px) {
        padding-top:100px;
    }
    @media screen and (min-width: 992px) {
        overflow-x: hidden;
    }
    @media screen and (min-width: 1922px) {
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
    } 
}
body.has-headerimg .page-wrapper {
    padding-top:0;
    @media screen and (max-width: 767px) {
        padding-top:50px;
    }
}
.container.container {
    max-width: calc(1090rem/16);
    width: 100%;
}
.container.container--narrow {
    max-width: calc(800rem/16);
}
.container.container--wysiwyg {
    max-width: calc(910rem/16);
}
.container.container--extra-narrow {
    max-width: calc(360rem/16);
}

.toc {
    top:-199999px;
}

.random-container {
    opacity: 0;
    transition: all .15s ease-in;
}