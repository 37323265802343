.highlights {
    margin-top: -140px;

    @media screen and (max-width: 767px) {
        margin-left:-15px;
        margin-right:-15px;
    }
}
.headings--highlights {
    padding:35px 15px 135px 15px;
    @media screen and (min-width: 768px) {
        padding-top:50px;
    }
}

@media screen and (max-width: 767px) {
    .slider.slider--highlights .slick-arrow {
        position: absolute;
        right:20px;
        top: auto;
        bottom:6px;
        transform: translateY(0);
    }
    .slider.slider--highlights .slick-arrow.slick-prev {
        right:70px;
        left: auto;
    }
}


.highlights-teaser {
    margin-bottom: 1rem;
}
.highlights-teaser__img {
    margin-bottom: 1.25rem;
}
.highlights-teaser__hl {
    text-transform: uppercase;
    margin-bottom: 1rem;
}