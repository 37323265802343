.btn.btn {
    position: relative;
    border-radius: 0;
    border-width:2px;
    padding:.5rem 1.5rem;
    text-transform: uppercase;
    font-size:0.95rem;
    text-decoration: none;
    font-family: var(--font-default-medium);
    transition: all .2s ease-in;
}

.btn-primary {

}
.btn.btn-link {
    padding:0 0 .25rem 0;
    border-width:0;
    border-color:inherit;
    color:inherit;
    position: relative;
}
.btn.btn-link:after {
    content:'';
    position: absolute;
    left:0;
    bottom:0;
    right:0;
    width:auto;
    height:2px;
    background:#000;
    transition: all 200ms ease;
}
.btn.btn-link--white {
    color:#fff;
}
.btn.btn-link--white:after {
    background:#fff;
}
.btn.btn-link:focus:after,
.btn.btn-link:hover:after {
    left:-5px;
    right:-5px;
}
.btn.btn-link:hover,
.btn.btn-link:focus {
    background: inherit;
}
.btn.btn-outline {
    color:#000;
    border-color:var(--color-primary);
    background:transparent;
}
.btn.btn-outline:hover,
.btn.btn-outline:focus {
    color:#fff;
    background:var(--color-primary);
}

.btn.btn-outline:after,
.btn.btn-primary:after {
    content: '';
    position: absolute;
    width: 0;
    opacity: 0.7;
    height: 100%;
    top: 0;
    left: 0;
}

.btn.btn-outline:hover:after,
.btn.btn-primary:hover:after {
    background-color: #FFF;
    filter: blur(1px);
    animation: slideX 0.6s cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-delay: .1s;
}

.btn.btn-outline--black {
    color:#000;
    border-color:#000;
}
.btn.btn-outline--black:hover,
.btn.btn-outline--black:focus {
    background:#000;
}
.btn.btn-outline--white {
    color:#fff;
    border-color:#fff;
}
.btn.btn-outline--white:hover,
.btn.btn-outline--white:focus {
    background:#fff;
    color:var(--color-primary);
}

@keyframes slideX {
    0% {
        width: 0;
        opacity: 0.7;
    }
    70% {
        width: 100%;
    }
    100% {
        opacity: 0;
    }
}