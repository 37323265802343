.content-slider {
    position: relative;
    padding:20px 0 0 0;
    @media screen and (min-width: 768px) {
        padding-top:35px;
    }
}
@media screen and (min-width: 768px) {
    .content-slider:after {
        content:'';
        pointer-events: none;
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        border:1px solid var(--color-primary);
    }
}
.content-slider__wrapper {
    position: relative;
    @media screen and (max-width: 767px) {
        background-color: rgba(248,242,233,0.8) !important;
    }
}
.content-slider__arrows.content-slider__arrows {
    position: absolute;
    bottom:40px;
    right:0;
    z-index:10;
    width:120px;

    @media screen and (min-width: 768px) {
        width:auto;
        bottom:auto;
        left:0;
        top:50%;
        transform: translateY(-50%);
    }
}
.content-slider__arrows .slick-arrow.slick-arrow {
    width: 44px;
    height:44px;
}
.content-slider__arrows .slick-arrow.slick-arrow:before {
    font-size: 1rem;
}
.content-slider__arrows .slick-arrow.slick-next {
    @media screen and (min-width: 768px) {
        right:33.333333%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin-right:20px;
    }
}

.content-slider__main {
    position: relative;
}
.content-slider__txt {
    padding:20px;

    @media screen and (min-width: 768px) {
        padding:30px;
    }
}

@media screen and (min-width: 768px) {
    .content-slider__thumbs {
        margin:25px auto 0 auto;
        padding-bottom:35px;
        width:100%;
        max-width:680px;
    }
}
.content-slider__thumb {
    margin:0 auto;
    padding:0 20px;
    opacity: 0.4;
    transition: all 200ms ease;
}
.content-slider__thumb:hover,
.slick-current .content-slider__thumb {
    opacity: 1;
}
.content-slider__thumb-txt {
    margin-top:10px;
    font-size: 15px;
    font-family: var(--font-default-medium);
    text-transform: uppercase;
}
.headings--contentslider {
    padding:0 15px;
}
.headings--contentslider h1,
.headings--contentslider .h1 {
    padding-bottom: 0;
    margin-bottom: 1.25rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 2rem;
    }
}
.headings--contentslider h1:after,
.headings--contentslider .h1:after {
    display: none;
}


@media screen and (min-width: 1200px) {
    .content-slider__main {
        margin:0 -75px;
    }
    .content-slider__txt {
        padding:30px 140px 30px 60px;
    }
}