.map {
    position: relative;
    overflow: hidden;
}

.map__intro-overlay {
    padding:2rem 1rem 0 1rem;
}

.map--desktop .map__intro-overlay {
    position: absolute;
    left:30px;
    top:115px;
    right:30px;
    z-index:10;
    padding:0;
}

.map__poi {
    display: block;
    width:10%;
    height:9%;
    transform:translateX(-50%) translateY(-50%);
    position: absolute;
    color:#fff;
    /*background: rgba(0,0,0,.25);*/
}

.map__poi--alpenrose {
    left: 47%;
    top: 48.5%;
}
.map__poi--loewebaer {
    left: 37%;
    top: 54.5%;
}
.map__poi--cavallino {
    left: 52%;
    top: 61%;
    width: 15%;
}
.map__poi--dachstein {
    left: 63%;
    top: 51%;
}



/* touch */
.map--touch .map__poi {
    width:15%;
    height:13%;
}
.map--touch .map__poi--alpenrose {
    left: 36%;
    top: 43%;
}
.map--touch .map__poi--loewebaer {
    left: 16%;
    top: 56%;
}
.map--touch .map__poi--cavallino {
    left: 43%;
    top: 68%;
    width:23%;
}
.map--touch .map__poi--dachstein {
    left: 68%;
    top: 48%;
    width:22%;
}