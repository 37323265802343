.sticky-btn{
    display: none;
}
@media screen and (max-width: 767px) {
    .sticky-btn{
        display: inline-block;
        transform:translateX(150px);
        transition: all 275ms ease;
    }
    .sticky-btn.is-sticky{
        position: fixed;
        right:0;
        bottom:30px;
        z-index:999;
        font-size: 1.5rem;
        padding: .5rem 1rem;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
        transform:translateX(0);
        line-height: 1px;
    }
}

footer {
    font-size: 14px;
    color:var(--color-primary);
    padding:2rem 0;
    background: #fff;
    @media screen and (max-width: 767px) {
        text-align:center;
    }
}
footer a {
    color:var(--color-primary);
}
footer a:hover,
footer a:focus {
    color:#000;
}
footer a.watch-video {
    display: inline-block;
    font-size: 18px;
    @media screen and (max-width: 767px) {
        margin-bottom:1rem;
    }
}
footer a.watch-video>.icon {
    position: relative;
    margin-right: 10px;
    top: 2px;
    font-size: 16px;
}

.footer-logo {
    width: calc(150rem/16);
}
