.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}
.custom-radio:after {
    clear: both;
}
.custom-radio label {
    @media screen and (max-width: 767px) {
        display: block;
    }
}
.custom-radio--inline {
    margin:5px 1px;
    display: block;
    @media screen and (min-width: 768px) {
        display: inline-block;
        margin:0 20px;
    }
}
.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: calc(12rem/16);
    border: 1px solid #000;
    background: #ffffff;
    width: calc(26rem/16);
    height: calc(26rem/16);
    border-radius: 50%;
    margin-top:-.1rem;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary-light);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:checked ~ .custom-radio__box,
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary-light);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger) !important;
}
.custom-radio__text {
    display: block;
    overflow: hidden;
    font-size:16px;
    text-transform: uppercase;
    font-family: var(--font-default-medium);
}