.form-errors {
    color: var(--color-danger);
    font-size: 14px;
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}