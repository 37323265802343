.ui-datepicker {
    font-family: inherit;
    background: #ffffff;
    color: #222222;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
    z-index: 9999 !important;
    font-size: 1rem;
    padding: .4em;
    width:18em;
    @media screen and (max-width: 767px) {
        font-size: 1.2rem;
    }
}
.ui-datepicker table {
    font-size: 1em;
}
.ui-datepicker-title,
.ui-datepicker-calendar th{
    font-family: var(--font-default-bold);
    font-weight: normal;
}
.ui-datepicker td a,
.ui-datepicker td span {
    text-align: center;
}
.ui-datepicker table .ui-state-hover,
.ui-datepicker table .ui-state-active {
    background: var(--color-primary);
    color: #ffffff;
}
.ui-datepicker .ui-state-disabled {
    opacity: .6;
}
.ui-datepicker-next,
.ui-datepicker-prev {
    text-align: center;
    line-height: 1.8em;
    cursor: pointer;

    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ui-datepicker-prev:before {
    content: var(--icon-arrow-back);
}
.ui-datepicker-next:after {
    content: var(--icon-arrow-next);
}
.ui-datepicker td a,
.ui-datepicker td span {
    padding: .4em;
}