.portalslider {
    margin-bottom: -10px;
}
.portal-slide {
    position: relative;
    border-bottom:1px solid #fff;
}
.portal-slide:after {
    content:'';
    width:auto;
    height:33%;
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    z-index:1;
}
.portal-slide__overlay {
    position: absolute;
    left:0;
    right:0;
    top:35px;
    bottom:25px;
    z-index:10;

    @media screen and (min-width: 768px) {
        top:230px;
        bottom:8%;
    }
}
.portal-slide__overlay-hl {
    font-size: 22px;
    line-height: 1.1;
    font-family: var(--font-default-bold);
    text-transform: uppercase !important;
    text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.25);
    margin:0;
    padding:0;

    @media screen and (min-width: 768px) {
        font-size: 60px;
    }
}
.portal-slide__overlay-hl:after {
    display: none;
}
.portal-slide__overlay-txt {
    color:#fff;
    font-size: 26px;
    font-weight:300;
    text-shadow: 1px 0px 2px rgba(0, 0, 0, 0.25);
    position: absolute;
    left:0;
    right:0;
    bottom:0;
}
.portal-slide__overlay-txt .btn {
    text-shadow: none;
}