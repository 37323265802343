.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);
}

@media screen and (min-width: 768px) {
    .content-block + .content-block,
    .pimcore_area_content + .pimcore_area_content > .content-block {
        margin-top: calc(80rem/16);
    }
}