.hotel-teaser {
    position: relative;
    transform: translate3d(0);
}
.hotel-teaser-row {
    margin:0 -2px;
}
.hotel-teaser-row__col {
    border: 1px #fff solid;
    border-width:2px 1px;
}

.hotel-teaser__overlay {
    background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    padding:15px 0;
    @media screen and (min-width: 1440px) {
        padding:20px 0;
    }
}
.hotel-teaser:hover .hotel-teaser__overlay {
    background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%);
}
.hotel-teaser__body {
    position: relative;
    padding:0 15px;
    @media screen and (min-width: 1440px) {
        padding:0 35px;
    }
}

.hotel-teaser:hover > .embed-responsive-teaser-hotel {
    filter: blur(2px);
    transition: all .2s;
    transform: translate3d(0);
}

.hotel-teaser--hover:hover .hotel-teaser__fadein {
    visibility: visible;
    opacity: 1;
}

.hotel-teaser__title {
    font-size: 24px;
    height: 90px;
    font-family: var(--font-default-bold);
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    line-height: 1.4;
    position: relative;
    margin-bottom: 25px;

    @media screen and (min-width: 1650px) {
        font-size: 36px;
        height: 150px;
        margin-bottom: 55px;

    }

}
.hotel-teaser__title:after,
.hotel-teaser__title:before {
    content:'';
    position: absolute;
    top:0;
    right:0;
    left:0;
    height:2px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.7);
}
.hotel-teaser__title:before {
    top:auto;
    bottom:0;
}

.hotel-teaser__title-text {
    max-width: 250px;
}

.hotel-teaser--hover .hotel-teaser__fadein {
    visibility: hidden;
    opacity: 0;
    transition: all .3s;

}

.hotel-teaser__text {
    text-shadow: 0 0 3px rgba(0,0,0,0.5);
    font-weight: 300;
    display: none;
    margin-bottom: 30px;

    @media screen and (min-width: 1440px) {
        display: block;
    }
}

.btn.btn-link:hover,
.btn.btn-link:focus {
    background: none;
}