.form {
    margin:1.5rem 0 3.5rem 0;
}

.form-group {
    margin-bottom: 1.5rem;
    position: relative;
}

.form-group--datepicker {
    margin-top: 3.5rem;
    @media screen and (max-width: 767px) {
        margin: 0;
    }
}

.form-group-lg {
    margin-bottom: 1.5rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 4rem;
    }
}
.required {
    position: absolute;
    right:10px;
    top:6px;
}
.form-headline {
    text-align: center;
    font-size:1.125rem;
    margin-bottom: 1.5rem;
}
.form-control {
    padding: .875rem 1.25rem;
    font-size: 15px;
    line-height: 1.5;
    color: var(--color-text-default);
    background-color: #fff;
    border: 1px solid var(--color-primary-light);
    border-radius:0;
}
textarea.form-control {
    min-height:195px; /* ie11 */
}
.form-control-lg {
    font-size: 18px;
    text-align: center;
    text-align-last:center;
    border: 0;
    cursor: pointer;
    @media screen and (min-width: 768px) {
        font-size: 35px;
    }
}
.form-control-lg option {
    font-size: 1rem;
}

select.form-control-lg:not([size]):not([multiple]) {
    height: calc(3.125rem + 2px);
    @media screen and (min-width: 768px) {
        height: calc(4.75rem + 2px);
    }
}

select.form-control option:checked {
    color: #FFF;
    background: var(--color-primary);
}

select.custom-select__box {
    appearance: none;
}

.form-control-lg.hasDatepicker {
    text-indent: -1rem;
    @media screen and (min-width: 768px) {
        text-indent: -1.75rem;
    }
}
.form-control.form-control-lg:focus {
    box-shadow: none;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #fff;
}
.has-error .form-control {
    border-color: var(--color-danger) !important;
}
.form-control::placeholder {
    color: var(--color-text-default);
}
select.form-control:not([size]):not([multiple]) {
    height: calc(3.125rem + 2px);
}
.label-form-control-lg {
    display: block;
    font-size: 15px;
    text-align: center;
    position: relative;
    top:14px;
}
.custom-select__container,
.datepicker {
    position: relative;
}
.custom-select__container:before,
.datepicker:after {
    content:'';
    width:12px;
    height:12px;
    background: #fff;
    border:2px solid #000;
    border-width:0 0 2px 2px;
    transform: rotate(-45deg) translateY(-50%);
    position: absolute;
    right:15px;
    top:55%;
    pointer-events: none;
    @media screen and (min-width: 768px) {
        right:18px;
    }
}

.row--form-inputs__col {
    border:1px solid var(--color-primary-light);
    background:#fff;
}
.row--form-inputs__col + .row--form-inputs__col {
    border-left:0;
}

@media screen and (max-width: 767px) {
    .row--form-inputs__col {
        margin-bottom:1.5rem;
    }
    .row--form-inputs__col:nth-child(3) {
        border-left:1px solid var(--color-primary-light);
    }
}
.child-age {
    margin-bottom: 1.5rem;
}
.child-age__label {
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    select.form-control--blank.form-control:not([size]):not([multiple]) {
        width:auto;
        border: 0;
        padding-top:0;
        padding-bottom:0;
        height:1.75rem;
        font-size: 18px;
        background: transparent !important;
        box-shadow: none !important;
    }
    .child-age__label {
        color: var(--color-primary);
    }
    .child-age {
        display: inline-block;
        white-space: nowrap;
        padding:0 .75rem 0 1.25rem;
        margin:0 0 .75rem 0;
    }
    .child-age + .child-age {
        border-left:1px solid var(--color-primary-light);
    }
}