/*Margin Helper*/


/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-transform-none {
    text-transform: none;
}


/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-black { color: #000; }
.text-color-default { color: var(--color-text-default); }


/*Background Color Helper*/
.bg-pattern {
    background: url(/static/img/pattern.png);
}
.bg-light {
    background-color: rgba(248,242,233,0.8) !important;
}
.bg-light-grey {
    background:#F3F3F3;
}



/* etc... */
.isClickable {
    cursor: pointer;
}
