.presse-teaser {
    background-color: #fff;
    margin-bottom: 1.5rem;
    border-bottom:1px solid var(--color-primary);
}
.presse-teaser:last-of-type {
    margin-bottom: 3rem;
}
.presse-teaser__body {
    padding:1rem;
    @media screen and (min-width: 768px) {
        padding:1rem 1.75rem;
    }
}