.embed-responsive-header::before{
    padding-top: 66.66%;
    @media screen and (min-width: 768px) {
        padding-top: 38.5%;
    }
}
.embed-responsive-portal::before{
    padding-top: 66.66%;
    @media screen and (min-width: 768px) {
        padding-top: 48.8%;
    }
}


.embed-responsive-gallery-grid-1by1:before {
    padding-top:66.66%;
    @media screen and (min-width: 768px) {
        padding-top:100%;
    }
}
.embed-responsive-gallery-grid-wide:before {
    padding-top:66.66%;
    @media screen and (min-width: 768px) {
        padding-top: 48%;
    }
}
.embed-responsive-gallery-grid-3by2::before{
    padding-top: 66.66%;
}


.embed-responsive-teaser-hotel::before{
    padding-top: 195%;
    @media screen and (max-width: 767px) {
        padding-top: 150%;
    }
}
.embed-responsive-2by1::before{
    padding-top: 50%;
}
.embed-responsive-3by2::before{
    padding-top: 66.66%;
}
.embed-responsive-3by1:before {
    padding-top: 33.33333%;
}

.embed-responsive-map:before {
    padding-top:68.45238095238095%;
}
.embed-responsive-map-touch:before {
    padding-top:66.2870159453303%;
}