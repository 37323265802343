.contact-teaser {
    line-height:1.25;
    margin-bottom:3rem;
    @media screen and (max-width: 767px) {
        background:#fff;
        padding:1rem;
        margin-bottom: 1.5rem;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
    }
}

.contact-teaser__phone {
    margin:3rem 0 2.5rem 0;

    @media screen and (max-width: 767px) {
        margin-top: 1rem;
    }
}

.contact-teaser__phone a>.icon {
    position: relative;
    top:4px;
    font-size:125%;
    line-height:1px;
    margin-right:2px;
}

.contact-teaser__www {
    font-size: 16px;
    text-transform: uppercase;
    font-family: var(--font-default-medium);
    margin-bottom:1rem;
}
.contact-teaser__address {
    font-size: 15px;
    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}