.table table {
    width: 100%;
    max-width: 100%;
}
.table table b,
.table table strong {
    color: #4A4A4A;
}
.table table > thead > tr > th,
.table table > tbody > tr > th,
.table table > tfoot > tr > th,
.table table > thead > tr > td,
.table table > tbody > tr > td,
.table table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.5;
    vertical-align: middle;
    text-align:center;
    background-color: transparent;
    border-top: 1px solid var(--color-text-default);
    @media screen and (min-width: 768px){
        font-size: 1.1rem;
        padding: 12px 16px;
    }
}


.table table > thead > tr:last-child > th,
.table table > tbody > tr:last-child > th,
.table table > tfoot > tr:last-child > th,
.table table > thead > tr:last-child > td,
.table table > tbody > tr:last-child > td,
.table table > tfoot > tr:last-child > td {
    border-bottom: 1px solid var(--color-text-default);
}
.table table > thead > tr > th {
    vertical-align: bottom;
    color: #fff;
    background: var(--color-primary);
    font-family: var(--font-default);
}

.table table > caption + thead > tr:first-child > th,
.table table > colgroup + thead > tr:first-child > th,
.table table > thead:first-child > tr:first-child > th,
.table table > caption + thead > tr:first-child > td,
.table table > colgroup + thead > tr:first-child > td,
.table table > thead:first-child > tr:first-child > td {
    border-top: 0;
}
.table-bordered {
    border: 1px solid var(--color-text-default);
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid var(--color-text-default);
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    /*border-bottom-width: 2px;*/
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f8f9fa;
}
.table-hover > tbody > tr:hover {
    background-color: #f8f9fa;
}
table col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
}
table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
}
.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}
@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive table {
        margin-bottom: 0;
    }
    .table-responsive > table > thead > tr > th,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tfoot > tr > td {
        white-space: nowrap;
    }
    .table-responsive > .table-bordered {
        border: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:first-child,
    .table-responsive > .table-bordered > tbody > tr > th:first-child,
    .table-responsive > .table-bordered > tfoot > tr > th:first-child,
    .table-responsive > .table-bordered > thead > tr > td:first-child,
    .table-responsive > .table-bordered > tbody > tr > td:first-child,
    .table-responsive > .table-bordered > tfoot > tr > td:first-child {
        border-left: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:last-child,
    .table-responsive > .table-bordered > tbody > tr > th:last-child,
    .table-responsive > .table-bordered > tfoot > tr > th:last-child,
    .table-responsive > .table-bordered > thead > tr > td:last-child,
    .table-responsive > .table-bordered > tbody > tr > td:last-child,
    .table-responsive > .table-bordered > tfoot > tr > td:last-child {
        border-right: 0;
    }
    .table-responsive > .table-bordered > tbody > tr:last-child > th,
    .table-responsive > .table-bordered > tfoot > tr:last-child > th,
    .table-responsive > .table-bordered > tbody > tr:last-child > td,
    .table-responsive > .table-bordered > tfoot > tr:last-child > td {
        border-bottom: 0;
    }
}